import Axios from "axios";

const AuthService = {

	isKeyValid() {
		const expiryTime = window.localStorage.getItem("spaceio-key-expiry");
		const now = Date.now();
		return Number(now) <= Number(expiryTime);
	},

	async fetchAuthKey() {
		let key = AuthService.authKey;
		if (AuthService.isKeyValid()) {
			return key
		};
		const newAuthKey = await this.refreshTokenAPI(AuthService.refreshToken);
		return newAuthKey;
	},

	get refreshToken() {
		let key = window.localStorage.getItem("spaceio-refresh-token");
		if (key === null) {
			return null
		};
		return key;
	},

	set refreshToken(key) {
		if (!key) return;
		window.localStorage.setItem("spaceio-refresh-token", key);
	},

	get authKey() {
		let key = window.localStorage.getItem("spaceio-key");
		if (key === null) return null
		return key;
	},

	set authKey(key) {
		if (!key) return;
		window.localStorage.setItem("spaceio-key", key);
	},

	getAuthToken: async (authCode) => {
		try {
			const res = await Axios({
				headers: {
					"x-auth-code": authCode,
				},
				method: 'post',
				url: 'https://prodws.afreespace.com/api/login/code?requiredRefreshToken=true'
			});
			const { 'x-auth-key': newAuthKey, 'x-auth-keyexpiry': expiry, 'x-refresh-token': refreshToken } = res.headers;
			// console.log("headers", newAuthKey, expiry, refreshToken);

			AuthService.authKey = newAuthKey;
			AuthService.refreshToken = refreshToken;
			window.localStorage.setItem('spaceio-key-expiry', Date.now() + Number(expiry));
		} catch (err) {
			console.error(err);
			throw (err.response.data.error)
		}
	},
	authenticationKey: function () {
		return localStorage.getItem("spaceio-key") ? localStorage.getItem("spaceio-key") : null
	},
	logout: () => {
		window.localStorage.removeItem("spaceio-key");
	},

	refreshTokenAPI: async (refreshKey) => {
		try {
			const res = await Axios({
				headers: {
					"x-refresh-token": refreshKey,
				},
				method: 'post',
				url: 'https://prodws.afreespace.com/api/refresh-token?requiredRefreshToken=true'
			});
			const { 'x-auth-key': newAuthKey, 'x-auth-keyexpiry': expiry, 'x-refresh-token': refreshToken } = res.headers;
			//console.log("headers", newAuthKey, expiry, refreshToken);

			AuthService.authKey = newAuthKey;
			AuthService.refreshToken = refreshToken;
			window.localStorage.setItem('spaceio-key-expiry', Date.now() + Number(expiry));

			return newAuthKey;
		} catch (err) {
			console.error(err);
			throw (err.response.data.error)
		}
	}
}
export default AuthService;