
import AuthService from "../Auth/auth";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom"

function Authenticate() {
    var key = localStorage.getItem('spaceio-key')
    const params = new URLSearchParams(window.location.search)
    const navigate = useNavigate();
    const [authCode, setAuthCode] = useState(params.has('xauthcode') ? params.get('xauthcode') : null)
    useEffect(() => {
        async function fetchToken() {
            const result = await AuthService.getAuthToken(authCode);
            navigate('/home')
        }
        fetchToken();
    }, [authCode])

    if (!params.has('xauthcode')) {
       window.location.href = 'https://login.afreespace.com/v2?referrer=https://pam-qrlinker.afreespace.com';
        //window.location.href = 'https://login.afreespace.com/v2?referrer=https://localhost.afreespace.com:8443'
    }
}



export default Authenticate;